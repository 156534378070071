import Vue from 'vue'
import VueCompositionApi, { reactive, toRefs } from '@vue/composition-api'
Vue.use(VueCompositionApi)

const reminderState = reactive({
	reportOurCompany: '',
	reportReminderNumber: '',
})

export default function storeReminder() {
	return {
		...toRefs(reminderState),
	}
}
