<template>
	<layout-default>
		<div class="about">
			Our Company: &nbsp;{{
				$store.getters.getReminderReportData.reminder_headers_company_rel
					.company_name
			}}<br />
			Customer: &nbsp;{{
				$store.getters.getReminderReportData.reminder_header_customer_rel
					.customer_title
			}}<br />
			Reminder Number: &nbsp;{{
				$store.getters.getReminderReportData.reminder_id
			}}<br />
			Date: &nbsp;{{ $store.getters.getReminderReportData.reminder_date }}<br />
			Due Date: &nbsp;{{ $store.getters.getReminderReportData.reminder_due_date
			}}<br />
			<b-button
				class="is-info margin-top-15"
				style="width: 200px"
				@click="openPDF"
				><i class="fas fa-search"></i>&nbsp; Preview</b-button
			>
			<div>
				<b-button
					class="is-success margin-top-15"
					style="width: 200px"
					@click="downloadPDF"
					><i class="fas fa-download"></i>&nbsp; Download</b-button
				>
			</div>
			<div>
				<b-button
					class="is-danger margin-top-15"
					style="width: 200px"
					@click="printPDF"
					><i class="fas fa-print"></i>&nbsp; Print</b-button
				>
			</div>
			<div>
				<b-button
					class="is-primary margin-top-15"
					@click="emailPDF"
					style="width: 200px"
					><i class="fas fa-envelope-square"></i>&nbsp; Email</b-button
				>
			</div>
			<div>
				<b-button class="is-danger margin-top-15" @click="createAnotherReminder"
					><i class="far fa-plus-square"></i>&nbsp; Create Another Reminder
				</b-button>
				<b-button
					class="is-success margin-top-15"
					style="margin-left: 30px"
					@click="viewAnotherReminder"
				>
					<i class="fas fa-search"></i>&nbsp; View Another Reminder</b-button
				>
			</div>
		</div>
	</layout-default>
</template>
<script>
import useReminderReportData from '@/_srcv2/views/pages/reminder/report/reminderReport'
import storeReminder from '@/_srcv2/pages/reminder/_shared/useReminderState.js'
import Store from '@/store'
import Router from '@/router'
export default {
	key: Store.getters.getKeyReminderReport,
	name: 'ReminderReport',
	setup() {
		const { reportReminderNumber } = storeReminder()
		// ----------------------------------------------------------------------------
		const { getReminderReportData } = useReminderReportData()
		const getData = () => {
			return new Promise((resolve, reject) => {
				const dd = getReminderReportData()
				if (dd !== undefined || null) {
					resolve(dd)
				} else {
					const errorObject = {
						msg: "Data couldn't be fetched from store",
					}
					reject(errorObject)
				}
			})
		}
		// ----------------------------------------------------------------------------------------
		const createReport = (dd) => {
			return new Promise((resolve, reject) => {
				if (dd !== undefined || null) {
					const pdfMake = require('pdfmake/build/pdfmake.js')
					const pdfFonts = require('pdfmake/build/vfs_fonts.js')
					pdfMake.vfs = pdfFonts.pdfMake.vfs
					const pdf = pdfMake.createPdf(dd)
					resolve(pdf)
				} else {
					const errorObject = {
						msg: "The report couldn't be created",
					}
					reject(errorObject)
				}
			})
		}
		const openPDF = () => {
			getData()
				.then((data) => createReport(data))
				.then((pdf) => pdf.open())
		}
		const fileName = reportReminderNumber.value
		// const fileName = Store.getters.getReminderReportData.reminder_id
		//   .trim()
		//   .replace(' ', '')
		//   .toLowerCase()
		const downloadPDF = () => {
			getData()
				.then((data) => createReport(data))
				.then((pdf) => pdf.download(`${fileName}.pdf`))
		}
		const printPDF = () => {
			getData()
				.then((data) => createReport(data))
				.then((pdf) => pdf.print())
		}
		const createAnotherReminder = () => Router.push({ name: 'SendReminder' })
		const viewAnotherReminder = () => Router.push({ name: 'ViewReminder' })
		const emailPDF = () =>
			alert(
				'This function is not supported on the current version of application!!!',
			)
		return {
			openPDF,
			downloadPDF,
			printPDF,
			emailPDF,
			createAnotherReminder,
			viewAnotherReminder,
		}
	},
}
</script>
<style scoped>
.margin-top-15 {
	margin-top: 15px;
}
</style>
